import React, { useMemo } from 'react';
import {
  GlobalSettingItemFragment,
  MediaImageFragment,
  PageCommunityDetail
} from '@src/graphql/gql-types';
import { getPageUrlPath } from '@src/utils/url';
import { ContentfulPageHyperlinkFragments } from '@src/types/contentful';
import Layout from '@src/components/Layout';
import SEO from '@src/components/SEO';
import { Themes } from '@src/types/themes';
import {
  getFormEnquiryDisclaimerEntry,
  getGlobalContactPhoneNumberEntry,
  getGlobalCTAPanelEntry,
  getSiteWideAlertBannerEntry
} from '@src/utils/globalSettings';
import { CommunityDetailPageProps } from '@src/types/page';
import {
  generateMetaTitle,
  getBreadcrumbs,
  getFooterNavItems,
  getOgImage,
  isCommunityPage,
  isNationalOrCommunityLandingPage
} from '@src/utils/helpers';
import HtmlComment from '@src/components/HtmlComment';
import HeroLarge from '@src/components/HeroLarge';
import ContentBlocks from '@src/components/ContentBlocks';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import IntroText from '@src/components/IntroText';
import Discover from '@src/components/Discover';
import { createReplaceDictionary } from '@src/utils/strings';
import GlobalCTAPanel from '@src/components/GlobalCTAPanel';
import GenericText from '@src/components/GenericText';
import { SITE_NAME } from '@src/constants/site';
import { useSetCommunityDetail } from '@src/hooks/useCommunityDetail';

type PageCommunityDetailTemplate = CommunityDetailPageProps;

const PageCommunityDetailTemplate: React.FC<PageCommunityDetailTemplate> = ({
  globalSettingsPayload,
  communityBasePagePayload,
  pagePayload,
  pageContentBlocks
}) => {
  const { page, pagePath } = pagePayload || {};
  const pageData = page?.items[0];
  const pagePathData = pagePath?.items?.[0] as ContentfulPageHyperlinkFragments;
  const breadcrumbs = getBreadcrumbs(pagePathData);

  const globalSettingItems = globalSettingsPayload?.globalSettings
    ?.items as GlobalSettingItemFragment[];
  const formDisclaimerRichText =
    getFormEnquiryDisclaimerEntry(globalSettingItems);
  const {
    communityDetail,
    footerNav,
    legalLinks,
    primaryNav: communityPrimaryNav,
    socialLinks
  } = communityBasePagePayload || {};
  const alertBanner = getSiteWideAlertBannerEntry(globalSettingItems);
  const globalCTAPanel = getGlobalCTAPanelEntry(globalSettingItems);

  const { title, seoTitle, seoDescription, noFollow, noIndex } = pageData ?? {};

  // Theming
  const theme = communityDetail?.theme as Themes;
  const isLiveChatEnabled = !!pageData?.isLiveChatEnabled;
  const displayGlobalCtaPanel = pageData?.displayGlobalCtaPanel;
  const hero = pageData?.hero;
  const displayTourBookingButton = !!pageData?.displayTourBookingButton;
  const publishedAt = hero?.sys.publishedAt ? hero?.sys.publishedAt : ''; // Use the hero published date : https://makernz.atlassian.net/browse/LEVMS-202
  const introText = pageData?.introText;
  const discoverBlock = pageData?.discoverBlock;
  const disclaimer = pageData?.disclaimer;
  const pageURLPath = getPageUrlPath(pagePathData);
  const isCommunity = isCommunityPage(pageData);

  const footerNavItems = getFooterNavItems(
    communityPrimaryNav,
    footerNav,
    socialLinks,
    legalLinks,
    breadcrumbs
  );

  // We don't pass the community name here since it should already exist in the title
  const metaTitle = seoTitle ?? generateMetaTitle([title, SITE_NAME]);
  const metaOgImage = getOgImage(hero?.mediaItem as MediaImageFragment);
  const heroLarge = hero && hero?.__typename === 'HeroLarge';

  const replacementDictionary = createReplaceDictionary(
    globalSettingItems,
    publishedAt
  );

  const communityDetailData = useMemo(() => {
    const communityDetails = communityBasePagePayload?.communityDetail;

    return {
      slug: communityDetails?.slug ?? '',
      title: communityDetails?.title ?? '',
      state: (communityDetails?.state?.slug ?? '').toUpperCase()
    };
  }, [communityBasePagePayload]);

  // sets community details in context for use in lead forms
  useSetCommunityDetail(communityDetailData);

  return (
    <Layout
      alertBannerData={alertBanner}
      primaryNav={
        communityPrimaryNav?.items[0]?.navigationElementsCollection?.items
      }
      footerNav={footerNavItems}
      showBreadCrumbs={
        !isNationalOrCommunityLandingPage(pagePayload?.page?.items[0])
      }
      pageCommunityData={pageData as PageCommunityDetail}
      communityName={pageData?.title}
      communitySlug={pageData?.slug}
      formDisclaimerRichText={formDisclaimerRichText}
      headOfficePhoneNumber={
        getGlobalContactPhoneNumberEntry(globalSettingItems)?.value
      }
      theme={theme}
      showLiveChat={isLiveChatEnabled}
    >
      <SEO
        metaTitle={metaTitle}
        metaDesc={seoDescription || ''}
        metaPageUrl={pageURLPath}
        metaOgImage={metaOgImage?.url}
        metaOgImageAlt={metaOgImage?.alt}
        noIndex={noIndex}
        noFollow={noFollow}
      />

      <HtmlComment comment="PageCommunityDetail Template" />

      {heroLarge && (
        <HeroLarge
          {...hero}
          displayTourBookingButton={displayTourBookingButton}
          isCommunity={isCommunity}
          formDisclaimerRichText={formDisclaimerRichText}
          headOfficePhoneNumber={
            getGlobalContactPhoneNumberEntry(globalSettingItems)?.value
          }
        />
      )}

      {introText && <IntroText introText={introText} />}

      {pageContentBlocks && (
        <ContentBlocks
          items={pageContentBlocks}
          pageType={CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY}
          sharedBuildData={{
            replacementDictionary
          }}
        />
      )}

      {displayGlobalCtaPanel && globalCTAPanel && (
        <GlobalCTAPanel
          isCommunity={true}
          formDisclaimerRichText={formDisclaimerRichText}
          {...globalCTAPanel}
        />
      )}

      {discoverBlock && <Discover {...discoverBlock} />}

      {disclaimer && disclaimer?.bodyContent && (
        <GenericText
          disclaimer
          {...disclaimer}
          pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
          sharedBuildData={{ replacementDictionary }}
        />
      )}
    </Layout>
  );
};

export default PageCommunityDetailTemplate;
