/**
 * This is the COMMUNITY PAGE
 * Holds the details on the community, lat/lon etc
 *  - Effectively the home page for the community
 */

import React from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import { apolloClient, setApolloHeaders } from '@src/services/apollo';
import {
  CommunityBasePageQuery,
  GlobalSettingsQuery,
  NationalBasePageQuery,
  PageCommunityDetailContentBlocksQuery,
  PageCommunityDetailPathQuery,
  PageCommunityDetailPathsQuery,
  PageCommunityDetailQuery,
  PageCommunityDetailQueryVariables
} from '@src/graphql/gql-types';
import PageCommunityDetailTemplate from '@src/templates/PageCommunityDetail';
import {
  pageCommunityDetailQuery,
  pageCommunityDetailPathsQuery,
  globalSettingsQuery,
  nationalBasePageQuery,
  communityBasePageQuery,
  pageCommunityDetailPathQuery,
  pageCommunityDetailContentBlocksQuery
} from '@src/graphql/queries';
import { CommunityDetailPageProps } from '@src/types/page';
import { flattenPagePaths } from '@src/utils/url';
import { getCommunityDetailSegmentFromFlattenPagePaths } from '@src/utils/helpers';
import { ContentBlocksItem } from '@src/types/contentBlocks';
import { base64EncodeHero } from '@src/utils/build/base64EncodeHero';

const CommunityDetailPage: React.FC<CommunityDetailPageProps> = (props) => (
  <PageCommunityDetailTemplate {...props} />
);

export default CommunityDetailPage;

/**
 * Create the paths params and cache the needed data to be passed to GetStaticProps
 * @param pages
 * @returns
 */
const createPathParams = (pages: any[]): any[] => {
  return pages
    .filter((page) => !!page?.slug)
    .map((page) => {
      const path = {
        params: {
          slug_community_detail: page?.slug ?? ''
        }
      };
      return path;
    });
};

/**
 * Build static paths
 * - Based on the GraphQL query `pageCommunityDetailPaths.query` will return __Community__ pages.
 * - Please confirm with checking in the GraphQL query for what is returned.
 * - Community pages live under the path
 *   - /community/[__community__]
 * @returns path params
 */

export const getStaticPaths: GetStaticPaths = async () => {
  const pageQueryResponse =
    await apolloClient.query<PageCommunityDetailPathsQuery>({
      query: pageCommunityDetailPathsQuery,
      variables: { preview: false }
    });

  if (pageQueryResponse?.errors) {
    console.error(pageQueryResponse.errors);
  }

  const pages =
    pageQueryResponse.data?.pageCommunityDetailCollection?.items || [];

  const paths = createPathParams(pages);

  return {
    paths,
    fallback: false
  };
};

export const getStaticProps: GetStaticProps = async ({ preview, params }) => {
  if (preview) {
    setApolloHeaders(process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN);
  }
  // Use the slug to look up the extra data required for the page
  const pageMetaData = await apolloClient.query<PageCommunityDetailPathQuery>({
    query: pageCommunityDetailPathQuery,
    variables: { slug: params?.slug_community_detail, preview: preview }
  });

  if (pageMetaData?.errors) {
    console.error(pageMetaData.errors);
  }

  // We need the navigation_id and community_id for the queries below
  const pageExtraData = pageMetaData.data?.pagePath?.items?.[0];
  const navigation_id = pageExtraData?.navigation?.sys?.id || '';
  const community_id = pageExtraData?.sys?.id || '';

  /**
   * Get an array of the page paths (This flattens the nested parent path into a usable array) */
  const arrayOfPagesPaths = flattenPagePaths(pageExtraData);
  // Get Community Details Segment , which will have reference to the Navigation to use
  const communityDetailSegments =
    getCommunityDetailSegmentFromFlattenPagePaths(arrayOfPagesPaths);

  const lookupData = {
    slug: pageExtraData?.slug ?? '',
    id: pageExtraData?.sys.id ?? '',
    navigation_id: communityDetailSegments?.navigation?.sys?.id || '',
    community_id: communityDetailSegments?.sys?.id || ''
  };

  const [
    globalSettingsQueryResponse,
    nationalBasePageQueryResponse,
    communityBasePageQueryResponse,
    pageQueryResponse,
    pageCommunityStandardContentBlocksQueryResponse
  ] = await Promise.all([
    apolloClient.query<GlobalSettingsQuery>({
      query: globalSettingsQuery,
      variables: {
        preview: preview
      }
    }),
    apolloClient.query<NationalBasePageQuery>({
      query: nationalBasePageQuery,
      variables: {
        preview: preview
      }
    }),

    apolloClient.query<CommunityBasePageQuery>({
      query: communityBasePageQuery,
      variables: {
        id: navigation_id,
        community_id: community_id,
        preview: preview
      }
    }),

    apolloClient.query<PageCommunityDetailQuery>({
      query: pageCommunityDetailQuery,
      variables: { slug: params?.slug_community_detail, preview: preview }
    }),

    apolloClient.query<
      PageCommunityDetailContentBlocksQuery,
      PageCommunityDetailQueryVariables
    >({
      query: pageCommunityDetailContentBlocksQuery,
      variables: { slug: lookupData?.slug, id: lookupData.id, preview: preview }
    })
  ]);

  if (globalSettingsQueryResponse?.errors) {
    console.error(pageQueryResponse.errors);
  }
  if (nationalBasePageQueryResponse?.errors) {
    console.error(nationalBasePageQueryResponse.errors);
  }
  if (communityBasePageQueryResponse?.errors) {
    console.error(communityBasePageQueryResponse.errors);
  }
  if (pageQueryResponse?.errors) {
    console.error(pageQueryResponse.errors);
  }

  const pagePayload = await base64EncodeHero(pageQueryResponse?.data);

  const templatePropsData: CommunityDetailPageProps = {
    globalSettingsPayload: globalSettingsQueryResponse?.data,
    nationalBasePagePayload: nationalBasePageQueryResponse?.data,
    communityBasePagePayload: communityBasePageQueryResponse?.data,
    pageContentBlocks: pageCommunityStandardContentBlocksQueryResponse.data
      .pageContentBlocks?.items[0]?.contentBlocksCollection
      ?.items as ContentBlocksItem[],
    pagePayload: pagePayload as PageCommunityDetailQuery
  };

  return {
    props: {
      ...templatePropsData
    }
  };
};
